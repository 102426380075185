import React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import { getMarkDownHeaders } from "../utils/markdownast"

const PostTopic = ({ data, location }) => {
  const { markdownRemark } = data
  const { slug, icon, name, info } = markdownRemark.frontmatter
  const html = markdownRemark.html
  const raw = markdownRemark.rawMarkdownBody
  const headers = getMarkDownHeaders(raw)
  const images = require.context("../images", true)
  const image = images(`./icons/${icon}@2x.png`)
  return (
    <>
      <header className="sub-header shadow-sm">
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <FormattedMessage id="menu-topics" />
              </li>
              <li className={slug === location.pathname ? "is-active" : ""}>
                <span>{name}</span>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section className="section is-medium post-topic-banner">
        <div className="container">
          <h1 className="title">
            <img src={image} alt={name} />
            {name}
          </h1>
          <p className="info">{info}</p>
        </div>
      </section>
      {html ? (
        <section className="section post-topic-content">
          <div className="container">
            <div className="main-content">
              <div
                className="post-content"
                id="mdwrapper"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
            <div className="right-panel">
              <ul className="post-headers">
                {headers.map((h, index) => {
                  return (
                    <li key={index} className="post-header-item">
                      {h.text}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  )
}

export default PostTopic
