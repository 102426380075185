import React from "react"
import SEO from "../layouts/seo"
import LayoutEn from "../layouts/en"
import LayoutZh from "../layouts/zh"
import { graphql } from "gatsby"

import PostTopic from "./post-topic"
import PostCourse from "./post-course"
import PostGuide from "./post-guide"

const Layouts = {
  en: LayoutEn,
  zh: LayoutZh,
}

const RealPosts = {
  topic: PostTopic,
  course: PostCourse,
  guide: PostGuide,
}

const Post = ({ data, location }) => {
  const { markdownRemark } = data
  const { name, langKey, type } = markdownRemark.frontmatter
  const Layout = Layouts[langKey]
  const RealPost = RealPosts[type]

  return (
    <Layout location={location} pageType="post-master">
      <SEO title={name} lang={langKey} />
      <RealPost data={data} location={location}></RealPost>
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query Post($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      rawMarkdownBody
      frontmatter {
        icon
        slug
        name
        info
        type
        langKey
      }
    }
  }
`
