import React from "react"
// import { FormattedMessage } from "react-intl"

const PostCourse = () => {
  return (
    <>
      <section className="section topic-banner">Course Banner</section>
    </>
  )
}

export default PostCourse
