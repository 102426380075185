import remark from "remark"
import visit from "unist-util-visit"
const rmk = remark()

const getMarkDownHeaders = mdstring => {
  const markdownAST = rmk.parse(mdstring)
  let result = []
  visit(markdownAST, `heading`, node => {
    if (node.children.length > 0) {
      node.children.map(n => {
        let item = {
          text: n.value,
        }
        result.push(item)
      })
    }
  })
  return result
}

export { getMarkDownHeaders }
